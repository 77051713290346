.source-control {
    height: auto;
    width: 20px;
    fill:none;
    stroke:#858585;
    stroke-miterlimit:10;
    stroke-width:1.5px;
}

.source-control-footer {
    height: 14px;
    width: auto;
    stroke: #fff;
    margin-right: 6px;
}