.vsc-footer {
    background-color: $vsc-footer;
    border-radius: 0 0 3px 3px;
    grid-column: 1 / -1;
    grid-row-end: -1;
    color: white;
    font-size: 12px;
    font-weight: 350;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vsc-footer__section {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 15px;
}

.vsc-footer__section.left > p {
    margin-right: 15px;
}

.vsc-footer__section.right > * {
    cursor: pointer;
    margin-left: 15px;
    text-decoration: none;
    color: white;
}