.tree {
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    vertical-align: middle;
    fill: white;
    cursor: pointer;
}

.tree__title-main {
    height: 24px;
    background-color: $vsc-open-editor;
    display: flex;
    align-items: center;
    padding-left: 8px;
    box-shadow: 0 4px 2px -2px #181818;
    font-weight: 500;
}

.tree__title-main > .tree__title-text {
    margin-left: 6px;
}

.tree__icon-close {
    margin-right: 10px;
    visibility: hidden;
}

.tree__icon-file {
    margin-right: 8px;
}

.tree__title {
    padding-left: 25px;
    display: flex;
    height: 26px;
    align-items: center;
}

.tree__title.active {
    background-color: rgba(48, 48, 54, 0.7);
}

.tree__title.active .tree__icon-close {
    visibility: visible;
}

.tree__title.active:hover {
    background-color: rgba(48, 48, 54, 0.7);
}

.tree__title:hover {
    background-color: rgba(42, 45, 46, 0.8);
}

.tree__title:hover .tree__icon-close {
    visibility: visible;
}

.tree__title-text {
    vertical-align: middle;
    padding-top: 1px;
}

.tree__content {
    will-change: transform, opacity, height;
    overflow: hidden;
}