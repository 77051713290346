// Background Colors
$vsc-header: #3F3E3E;
$vsc-app-panel: #2D2D2D;
$vsc-terminal: #1E1E1E;
$vsc-file: #1E1E1E;
$vsc-project-slider: #252526;
$vsc-footer: #0075C4;
$vsc-open-editor: #383838;
$vsc-inactive-file-tab: #2D2D2D;

// Font Colors
$vsc-off-white: #cccccc;