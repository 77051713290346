.xr__title {
    font-weight: 400;
}

.xr__project {
    border: 1px solid $vsc-footer;
    background: $vsc-app-panel;
    margin: 10px 0;
    padding: 10px;
    line-height: 1.2;
}