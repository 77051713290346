.vsc-app-panel {
    align-items: center;
    background-color: $vsc-app-panel;
    display: flex;
    flex-direction: column;
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    justify-content: space-between;
}

.vsc-app-panel__menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.vsc-app-panel__menu > svg {
    margin: 16px 0;
}

.vsc-app-panel__menu > svg:first-child {
    stroke:#ffffff;
}

.vsc-app-panel svg:hover {
    stroke:#ffffff;
}

.vsc-app-panel__settings {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
}
