.vsc-terminal {
    background-color: $vsc-terminal;
}

.pane2.vsc-terminal {
    overflow: hidden;
}

.vsc-terminal__titlebar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    padding: 0 20px;
}

.vsc-terminal__titlebar-icons {
    display: flex;
    align-items: center;
}

.vsc-terminal__title-underline {
    border: 0.5px solid white;
    width: 52px;
    float: left;
    margin-left: 20px;
}

.vsc-terminal__titlebar-arrow {
    transform: rotate(180deg);
}

.vsc-terminal__titlebar-close {
    fill: #858585;
    // cursor: pointer;
}

.vsc-terminal__titlebar-icon-container {
    margin-left: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vsc-terminal__form {
    margin: 20px 0 10px 20px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.vsc-terminal__form label {
    display: flex;
    flex-grow: 1;
    color: #8B40CD;
}

.vsc-terminal__form input {
    background: none;
    border: none;
    margin-left: 10px;
    font-weight: 200;
    caret-color: white;
    color: white;
    width: 500px;
}

.vsc-terminal__form-instructions {
    margin-bottom: 5px;
    font-style: italic;
    font-weight: 100;
}

.vsc-terminal__form-completion {
    font-weight: 100px;
    color: #40C653;
}

.vsc-terminal__form textarea {
    background: none;
    color: white;
    margin-left: 10px;
    border: none;
    resize: none;
    width: 500px;
    height: 100px;
    font-weight: 200;
}

.vsc-terminal__form textarea:focus {
    outline: none;
}

.vsc-terminal__form input:focus {
    outline: none;
}
