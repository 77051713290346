.vsc-header {
    align-items: center;
    background-color: $vsc-header;
    border-bottom: 0.5px solid $vsc-file;
    border-radius: 3px 3px 0 0;
    display: flex;
    grid-column: 1 / -1;
    grid-row-start: 1;
}

.vsc-header__buttons {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 60px;
    margin-left: 3px;
}

.vsc-header__text {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}