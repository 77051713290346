.readme {
    height: 12px;
    width: auto;
    fill:#5391B7;
    stroke: none;
}

.javascript {
    height: 8px;
    width: auto;
    fill:#EAEA21;
    stroke: none;
}

.jsx {
    height: 12px;
    width: auto;
}

.jsx .cls-1 {
    fill:#61dafb;
}

.python {
    height: 12px;
    width: auto;
}

.python .cls-1 {
    fill: #456E9A;
}

.python .cls-2 {
    fill: url(#linear-gradient-2);
}

.csharp {
    height: 14px;
}

.csharp .cls-1 {
    fill:#9a4993;
}

.csharp .cls-2 {
    fill:#6a1577;
}

.csharp .cls-3{
    fill:#813084;
}

.csharp .cls-4{
    fill:#fff;
}

.terraform {
    height: 12px;
    width: auto;
    fill:#5c4ee5;
}

.terraform .cls-1 {
    fill:#4040b2;
}

.terraform .cls-2 {
    fill:#5c4ee5;
}

.vscode {
    height: 12px;
}

.vscode .cls-1 {
    fill:#fff;
}

.vscode .cls-1, .cls-7 {
    fill-rule:evenodd;
}

.vscode .cls-2 {
    mask:url(#mask);
}

.vscode .cls-3 {
    fill:#0065a9;
}

.vscode .cls-4 {
    fill:#007acc;
}

.vscode .cls-5 {
    fill:#1f9cf0;
}

.vscode .cls-6 {
    opacity:0.25;
}

.vscode .cls-7 {
    fill:url(#linear-gradient);
}