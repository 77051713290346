* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    max-height: 100vh;
    background-color: $vsc-file;
    color: $vsc-off-white;
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}