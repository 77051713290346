.welcome {
    padding: 30px;
}

.welcome__title {
    margin-bottom: 5px;
    font-weight: 400;
}

.welcome__subtitle {
    color: #808080;
    font-weight: 400;
    margin-bottom: 30px;
}

.welcome__new > h3 {
    color: $vsc-footer;
    font-weight: 400;
    margin-bottom: 8px;
}

.welcome__new div {
    color: $vsc-footer;
    margin-bottom: 5px;
}

.welcome__new p {
    margin-bottom: 20px;
    line-height: 1.2;
}

.welcome__new ul {
    margin-left: 20px;
}

.welcome__new li {
    margin-bottom: 10px;
}
