.circle {
    height: 12px;
    width: 12px;
    fill:none;
    stroke:black;
    stroke-miterlimit:10;
    stroke-width:0.8px;
}

.circle.circle-close {
    fill:#ff605c;
    stroke:#B9463B;
}

.circle.circle-minimize {
    fill:#FFBC4F;
    stroke:#D09A37;
}

.circle.circle-expand {
    fill:#00CA58;
    stroke:#489B36;
}