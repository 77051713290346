.draggable-main {
    overflow: hidden;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $vsc-inactive-file-tab;;
}
  
.content {
    position: relative;
    width: 160px;
    height: 35px;
}
  
.content__file {
    position: absolute;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    height: 35px;
    overflow: visible;
    cursor: pointer;
    transform-origin: 50% 50% 0px;
    line-height: 35px;
    text-align: center;
    font-size: 12px;
    background-color: $vsc-inactive-file-tab;
    border-right: 0.1px solid $vsc-file;
    border-left: 0.1px solid $vsc-file;
}

.content__file.active {
    background-color: $vsc-file;
    color: #ffffff;
}

.content__file.active .content__file-close {
    visibility: visible;
}

.content__body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content__file-icon {
    margin: -1px 10px 0 0;
}

.content__file div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 18px;
}

.content__file-close {
    visibility: hidden;
    margin-top: -1px
}

.content__file:hover .content__file-close{
    visibility: visible;
}