.vsc {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: 22px auto 22px;
    height: 100vh;
    width: 100vw;
}

.vsc-mobile {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 0 20px;
    text-align: center;
}

