.read-me h1{
    font-weight: 400;
}

.read-me > p {
    margin-bottom: 30px;
}

.read-me h2 {
    margin: 15px 0 10px 0;
    font-weight: 400;
}

.read-me a {
    color: #8B40CD;
}

.read-me a:visited {
    color: #8B40CD;
}

.read-me__skills {
    display: flex;
}

.read-me__skills p:nth-child(2) {
    color: $vsc-footer;
}

.read-me__skills p {
    margin-right: 5px;
}

.read-me__certifications {
    margin-left: 25px;
}