.resize {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
}

.split-pane {
    width: 100%;
    height: 100%;
    display: flex;
}

.split-pane.horizontal {
    flex-direction: column;
}

.pane1,
.pane2 {
    flex: 1;
    overflow: hidden;
}

.seperator {
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.separator.vertical {
    width: 6px;
    margin: 0 -3px;
    cursor: col-resize;
}

.separator.horizontal {
    height: 11px;
    border-top: 1px solid $vsc-open-editor;
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    background-color: $vsc-terminal;
    cursor: row-resize;
    width: 100%;
}